


























































import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Subscription } from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
  },
})
export default class LatestInvoice extends Vue {
  @Getter("profile/subscriptionDetails") subscriptionDetails!: Subscription;

  // TODO: Map all subscriptionDetails.latestInvoice.status options to i18n

  formatPrice(amount: number) {
    return `€${amount.toFixed(2)}`;
  }

  formatAmount(amount: number) {
    // Use on all calculations with currency amounts per value to prevent 3rd decimal (.xx5) rounding issues of percent/tax calculations
    return parseFloat((amount / 100).toFixed(2));
  }
}
