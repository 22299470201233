import { render, staticRenderFns } from "./LatestInvoice.vue?vue&type=template&id=573aae38&scoped=true&"
import script from "./LatestInvoice.vue?vue&type=script&lang=ts&"
export * from "./LatestInvoice.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573aae38",
  null
  
)

export default component.exports