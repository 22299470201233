import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"setting-title w-full d-flex justify-space-between align-center",staticStyle:{"height":"30px"}},[_c('div',[_vm._v(_vm._s(_vm.$t("subscription.latestInvoice"))+":")]),_c(VBtn,{attrs:{"text":"","disabled":""}},[_vm._v(_vm._s(_vm.$t("subscription.viewAll")))])],1),_c(VList,[_c(VListItem,{staticClass:"mb-4 grey lighten-5"},[_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticStyle:{"min-width":"30%"}},[_c(VIcon,{staticClass:"mr-8"},[_vm._v("mdi-receipt-text-check-outline")]),_c('b',[_vm._v(_vm._s(_vm.$t("subscription.invoiceNr"))+":")]),_vm._v(" "+_vm._s(_vm.subscriptionDetails.latestInvoice.number)+" ")],1),_c(VCol,[_vm._v(" "+_vm._s(new Date( _vm.subscriptionDetails.latestInvoice.created * 1000 ).toLocaleDateString(_vm.LocalStorage.getLocale()))+" ")]),_c(VCol,[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.formatAmount(_vm.subscriptionDetails.latestInvoice.total)))+" ")]),_c(VCol,[_c('b',[_vm._v(_vm._s(_vm.$t("subscription.status"))+":")]),_c(VBadge,{staticClass:"ml-2",attrs:{"content":_vm.subscriptionDetails.latestInvoice.status === 'paid'
                ? _vm.$t('subscription.paid')
                : _vm.subscriptionDetails.latestInvoice.status,"color":"success"}})],1),_c(VCol,[_c('div',{staticClass:"text-right"},[_c(VBtn,{attrs:{"link":"","href":_vm.subscriptionDetails.latestInvoice.invoice_pdf,"target":"_blank"}},[_c(VIcon,[_vm._v("mdi-download")]),_vm._v(_vm._s(_vm.$t("subscription.downloadInvoice")))],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }